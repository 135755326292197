
import Vue, { PropType } from "vue";
import ListTemplate from "@/components/templates/ListTemplate.vue";
import MemoList from "@/components/organisms/MemoList.vue";
import MemoDetail from "@/components/organisms/MemoDetail.vue";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/SimpleDialog.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { Dialog } from "@/lib/utils";
import { Memo } from "@/lib/models/memo";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { ListTemplate, MemoList, MemoDetail },
  data() {
    return {
      isChanged: false,
      novelId: (this.$route.query.novelId as string) ?? "all",
    };
  },
  props: {
    memoId: {
      type: String as PropType<string>,
    },
  },
  created() {
    this.$store.dispatch("memoModule/initialize");
    window.addEventListener("beforeunload", this.beforeUnload);
  },
  mounted() {
    const { $refs, $route, $router, novelId, recentMemoId } = this;
    const { memoList } = $refs as any;
    const memos = memoList.memos as Memo[];

    if ($route.params.memoId === recentMemoId) return;

    if (memos.length === 0) {
      memoList.onCreateMemoButtonClick();
    }

    let memoId;
    if (memos.some((memo) => memo.id === recentMemoId)) memoId = recentMemoId;
    else memoId = memos[0].id;
    $router.push({
      name: $route.name as string,
      params: { memoId },
      query: { novelId },
    });
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.beforeUnload);
  },
  computed: {
    recentMemoId() {
      return this.$store.getters["memoModule/recentMemoId"];
    },
  },
  methods: {
    onUpdatedIsChanged(isChanged: boolean) {
      this.isChanged = isChanged;
    },
    showLeaveConfirmDialog(next) {
      const confirmDialog = new Dialog(SimpleDialog);
      const options: SimpleDialogProps = {
        title: "開いているメモを編集中です。",
        content: "保存していないデータが存在します。\nこのまま画面遷移しますか？\n※編集前のデータに戻ります。",
        negative: "キャンセル",
        positive: "遷移する",
        positiveCallback: () => {
          // このタイミングでnext()を実行すると
          // なぜかもう一度beforeRouteLeaveが走るので、isChangedをfalseにしてダイアログを出さずに画面遷移をするようにする。
          this.isChanged = false;
          next();
        },
      };

      confirmDialog.show(options);
    },
    beforeUnload(event): void {
      if (this.isChanged) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = "開いているメモを編集中です。";
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isChanged) {
      this.showLeaveConfirmDialog(next);
      return;
    }
    if (to.query) {
      this.novelId = (to.query.novelId as string) ?? "all";
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChanged) {
      this.showLeaveConfirmDialog(next);
      return;
    }
    next();
  },
  watch: {
    $route(to: Route, from) {
      if (to.query) {
        this.novelId = (to.query.novelId as string) ?? "all";
      }
    },
  },
});

interface Data {
  isChanged: boolean;
  novelId: string;
}

interface Props {
  memoId: string;
}

interface Computed {
  recentMemoId: string;
}

interface Methods {
  showLeaveConfirmDialog: (next: NavigationGuardNext<Vue>) => void;
  beforeUnload: (event: BeforeUnloadEvent) => void;
  onUpdatedIsChanged: (isChanged: boolean) => void;
}
